import React from "react"

import Layout from "../components/layout"
import * as styles from '../styles/pages/about.module.css'

import image_videoholder from '../images/videoholder.png'
import image_divider from '../images/about/divider.png'
import image_logo from '../images/about/boysen_icon.png'

import image_social_fb from '../images/about/social_fb.png'
import image_social_ig from '../images/about/social_ig.png'
//import image_social_twitter from '../images/about/social_twitter.png'
import image_social_youtube from '../images/about/social_youtube.png'
import image_social_pinterest from '../images/about/social_pinterest.png'


const sociallist = [
	{
		"title": "Pinterest",
		"url": "https://www.pinterest.ph/boysenpaints/",
		"img": image_social_pinterest
	},
	{
		"title": "Instagram",
		"url": "https://www.instagram.com/boysenpaintsphilippines/",
		"img": image_social_ig
	},
	{
		"title": "Facebook",
		"url": "https://www.facebook.com/konstruktchem/",
		"img": image_social_fb
	},
	/*
	{
		"title": "Twitter",
		"url": "https://www.twitter.com/boysenpaints/",
		"img": image_social_twitter
	},
	*/
	{
		"title": "Youtube",
		"url": "https://www.youtube.com/user/BoysenPaintsPhil/",
		"img": image_social_youtube
	}
]

const AboutPage = () => (
  <Layout activePath='about' pageTitle="About Konstrukt">
  	<div className="bg-cement-tile">
  		<br/>
	    <h1 class='font-size-large header-title fullblock-mobile-only'>About Konstrukt</h1>
  		<br/>
		<div className="text-centered">
			<div className={styles.videoholder}>
				<img src={image_videoholder} alt="" className="fullblock" />
				<iframe title='About Konstrukt' src="https://www.youtube.com/embed/mpCQJ1QbDMY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div>
		</div>
		<div className={"margin-content "+styles.maintitle}>
			<h2 className="font-size-huge font-style-sectiontitle">For Pros. By Pros.</h2>
			<p>Konstrukt&trade; provides advanced construction chemical solutions to enable excellent surface preparation of substrates used in floors, walls, and ceilings.</p>
			<p>For over a decade, we have been leveraging the experience and expertise gained by Pacific Paint (BOYSEN<span className='registeredicon'>&reg;</span>) Philippines, Inc., a regional player in the chemical industry. We have listened to our customers very closely and have continually been coming up with innovative solutions to these particular sets of challenges that they encounter.</p>
			<p>With Konstrukt&trade;, our clients are assured that our solutions are of the same high quality and superior durability that has made BOYSEN<span className='registeredicon'>&reg;</span> Paints the number one paint manufacturer in the Philippines.</p>
			<p>Every need is carefully considered, every chemical system solution rigorously developed and manufactured.</p>
			<p>Konstrukt&trade; is created for pros, by pros.&nbsp;<span className="color-text-primary">&#9607;</span></p>
		</div>
		<div className={styles.footerItemHolder+" margin-content"}>
			<div className={styles.footerItem+" "+styles.footerItemCorporate}>
				<span class="font-size-small font-style-bold">BOYSEN<br/>Corporate<br/>Site</span>
				<img src={image_divider} className={styles.footerItemDivider} alt=""/>
				<a rel="noopener noreferrer" target="_blank" href="https://boysen.com.ph" className={styles.footerItemLogo+" "+styles.footerItemLink}>
					<img src={image_logo} alt="BOYSEN"/>
				</a>
			</div>
			<div className={styles.footerItem+" "+styles.footerItemSocial}>
				<span class="font-size-small font-style-bold">Socials</span>
				<img src={image_divider} className={styles.footerItemDivider} alt=""/>
				{sociallist.map((data, index) => {
					return (
						<a rel="noopener noreferrer" target="_blank" href={data.url} className={styles.footerItemSocial+" "+styles.footerItemLink}>
							<img src={data.img} alt={data.title}/>
						</a>
					)
				})}
			</div>
		</div>
  	</div>
  </Layout>
)

export default AboutPage
